import { stripRichTextWrapperTag, richText } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export const hookPushSanteAdapter = (slices, isFirstModule) => {
  const introData = slices?.find(({ slice_type }) => slice_type === 'intro');

  const intro = {
    supertitle: stripRichTextWrapperTag(introData?.primary?.supertitle),
    title: stripRichTextWrapperTag(introData?.primary?.title),
    titleTag: isFirstModule ? 'h1' : 'h3',
    description: richText(introData?.primary?.description),
  };

  const rangesData = slices?.filter(
    ({ slice_type }) => slice_type === 'input_sante'
  );

  const valueToData = {};
  const ranges = rangesData?.map(({ primary, items }) => {
    valueToData[primary.name] = {};
    return {
      label: primary.label,
      name: primary.name,
      icon: imageAdapter(primary.icon),
      infobulle: primary.infobulle
        ? `<button type="button" data-tooltip="${primary.infobulle}">i</button>`
        : null,
      steps: items?.map(({ value, label, infobulle }, index) => {
        valueToData[primary.name][index] = value;
        return {
          dataValue: value,
          label,
          infobulle,
        };
      }),
    };
  });

  return {
    valueToData,
    data: {
      intro,
      ranges,
    },
  };
};

export const hookPushEmprunteurAdapter = (slices, isFirstModule) => {
  const introData = slices?.find(({ slice_type }) => slice_type === 'intro');

  const intro = {
    supertitle: stripRichTextWrapperTag(introData?.primary?.supertitle),
    title: stripRichTextWrapperTag(introData?.primary?.title),
    titleTag: isFirstModule ? 'h1' : 'h3',
    description: richText(introData?.primary?.description),
  };

  const inputsHiddenData = slices?.filter(
    ({ slice_type }) => slice_type === 'input_hidden'
  );

  const inputsHidden = inputsHiddenData?.map(({ primary }) => ({
    title: primary.label,
    name: primary.name,
    defaultValue: primary.value,
  }));

  const inputsData = slices?.filter(
    ({ slice_type }) => slice_type === 'input_emprunteur'
  );

  const inputs = [
    ...inputsHidden,
    ...inputsData?.map(({ primary, items }) => ({
      title: primary.label,
      name: primary.name,
      inputs: items?.map(
        ({ notification_id, extra_step_id, value, label, icon }) => {
          return {
            notificationId: notification_id,
            extraStepId: extra_step_id,
            value,
            label,
            icon: imageAdapter(icon),
          };
        }
      ),
    })),
  ];

  const notificationsData = slices?.filter(
    ({ slice_type }) => slice_type === 'notification_emprunteur'
  );

  const notifications = notificationsData?.map(({ primary }) => ({
    notificationId: primary?.notification_id,
    title: primary?.notification_title,
    message: stripRichTextWrapperTag(primary?.notification_message),
    displayByDefault: primary?.display_without_id,
  }));

  const extraStepsData = slices?.filter(
    ({ slice_type }) => slice_type === 'extra_step_emprunteur'
  );

  const extraSteps = extraStepsData?.map(({ primary, items }) => ({
    extraStepId: primary?.extra_step_id,
    title: primary?.extra_step_label,
    name: primary?.extra_step_name,
    displayByDefault: primary?.display_by_default,
    inputs: items?.map(({ label, value, infobulle }) => ({
      label,
      value,
      infobulle,
    })),
  }));

  const cardStepsData = slices?.find(
    ({ slice_type }) => slice_type === 'card_steps_emprunteur'
  );

  const cardSteps = cardStepsData && {
    title: stripRichTextWrapperTag(cardStepsData?.primary?.title),
    titleTag: isFirstModule ? 'h1' : 'h3',
    steps: cardStepsData?.items?.map(({ item_title, item_description }) => ({
      title: item_title,
      subtitle: item_description,
    })),
  };

  return {
    notifications,
    extraSteps,
    data: {
      intro,
      inputs,
      cardSteps,
      staticExtraSteps: extraSteps?.filter(
        ({ displayByDefault }) => !!displayByDefault
      ),
      staticNotifications: notifications?.filter(
        ({ displayByDefault }) => !!displayByDefault
      ),
    },
  };
};

export default async (
  { primary },
  data,
  { $prismic, $enhancedLinkSerializer },
  { sliceIndex }
) => {
  const moduleId = primary?.hook_module?.id;
  const isFirstModule = sliceIndex === 0;

  if (!moduleId) {
    return;
  }

  const hookModule = await $prismic.client.getByID(moduleId);
  const hookModuleData = hookModule?.data;
  const { type } = hookModuleData;

  const componentData = {};
  const hookPush =
    type === 'sante'
      ? hookPushSanteAdapter(hookModuleData.body, isFirstModule)
      : hookPushEmprunteurAdapter(hookModuleData.body, isFirstModule);

  if (type === 'sante') {
    componentData.withGrayBackground = primary.with_gray_background;
    componentData.endDescription = stripRichTextWrapperTag(
      hookModuleData.cta_mention
    );
  }

  return {
    type,
    cta: $enhancedLinkSerializer(hookModuleData?.cta_link),
    hasFixedButton: primary.fixed_button,
    submitUrl: hookModuleData?.cta_link?.url,
    valueToData: hookPush?.valueToData,
    notifications: hookPush?.notifications,
    extraSteps: hookPush?.extraSteps,
    component: {
      ctaLabel: hookModuleData?.cta_label,
      isCentered: primary.is_centered,
      ...componentData,
      ...hookPush?.data,
    },
  };
};
